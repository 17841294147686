<template>
  <v-container>
    <h1>{{ verb }} Test</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.name"
              :counter="100"
              :disabled="loading"
              :rules="rules.name"
              label="Name"
              class="mt-2"
              required
              validate-on-blur
            ></v-text-field>
            <v-text-field
              v-model="form.duration"
              :disabled="loading"
              :rules="rules.duration"
              label="Duration (in minutes)"
              class="mt-2"
              type="number"
              required
              validate-on-blur
            />
            <div class="mt-2">
              <v-datetime-picker
                v-model="form.test_start"
                :disabled="loading"
                dateFormat="yyyy-MM-dd"
                timeFormat="HH:mm"
                label="Test Start"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </div>
            <div class="mt-1">
              <v-datetime-picker
                v-model="form.test_end"
                :disabled="loading"
                label="Test End"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </div>
            <v-autocomplete
              v-model="form.eligible_groups"
              :disabled="loading"
              :items="eligibleGroups"
              :loading="eligibleGroupLoading"
              :search-input.sync="groupSearch"
              label="Eligible Groups"
              item-text="name"
              item-value="id"
              cache-items
              chips
              clearable
              flat
              hide-no-data
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeEligibleGroupChip(data.item.id)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
            <v-radio-group
              v-model="form.calculation_system"
              label="Calculation System"
            >
              <v-radio label="Basic System" value="basic" />
              <v-radio label="IRT System" value="irt" />
              <v-radio label="Minus System" value="minus" />
            </v-radio-group>
            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-4"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { format, formatISO } from "date-fns";
import { mapActions } from "vuex";
import Group from "@/services/group";
import Test from "@/services/test";

export default {
  name: "Test.Form",
  data() {
    return {
      valid: false,
      form: {
        name: "",
        duration: 0,
        test_start: "",
        test_end: "",
        eligible_groups: [],
        calculation_system: ""
      },
      institutes: [],
      rules: {
        name: [
          v => !!v || "Name is required",
          v =>
            (v.length >= 5 && v.length <= 100) ||
            "Name must be greater than 5 and less then 100",
          v =>
            new RegExp(/^[\w\-\s]+$/i).test(v) ||
            "Name must only contain alphanumeric and whitespace characters"
        ],
        duration: [
          v => !!v || "Duration is required",
          v =>
            (v > 0 && v <= 600) ||
            "Duration must be greater than 0 and less than or equal to 600"
        ],
        calculation_system: [v => !!v || "Calculation system is required"]
      },
      search: null,
      model: null,
      loading: false,
      menu: false,
      eligibleGroups: [],
      eligibleGroupLoading: false,
      groupSearch: ""
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    }
  },
  watch: {
    groupSearch(val) {
      val && this.searchGroups(val);
    }
  },
  async created() {
    this.setNavbarTitle("Manage Test");
    if (this.dataId) {
      const group = await Test.find(this.dataId);
      const {
        name,
        duration,
        testEnd,
        testStart,
        eligibleGroups,
        calculationSystem
      } = group.data.data;
      this.form = {
        name,
        duration,
        test_start: format(new Date(testStart), "yyyy-MM-dd HH:mm"),
        test_end: format(new Date(testEnd), "yyyy-MM-dd HH:mm"),
        eligible_groups: eligibleGroups.map(({ groupId }) => groupId),
        calculation_system: calculationSystem
      };
      this.eligibleGroups.push(
        ...eligibleGroups.map(({ groupId, group: { name } }) => ({
          id: groupId,
          name
        }))
      );
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async searchGroups(query) {
      this.eligibleGroupLoading = true;
      const groups = await Group.search(query);
      this.eligibleGroups = groups.data.data.groups;
      this.eligibleGroupLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        if (this.form.test_start === "" || this.form.test_end === "") {
          this.$snackbar.dismiss();
          return this.$snackbar.showMessage({
            content: "Please fill in Test Start and Test End form",
            variant: "warning"
          });
        }

        this.loading = true;
        try {
          if (this.dataId) {
            await Test.update(this.dataId, {
              ...this.form,
              test_start: formatISO(new Date(this.form.test_start)),
              test_end: formatISO(new Date(this.form.test_end))
            });
          } else {
            await Test.create({
              ...this.form,
              test_start: formatISO(new Date(this.form.test_start)),
              test_end: formatISO(new Date(this.form.test_end))
            });
          }

          this.$snackbar.showMessage({
            content: `Data ${this.pastVerb.toLowerCase()}`,
            variant: "success"
          });
          this.$router.push({ name: "Test.Index" });
        } catch (error) {
          this.loading = false;
        }
      }
    },
    removeEligibleGroupChip(item) {
      const index = this.form.eligible_groups.indexOf(item);
      if (index >= 0) this.form.eligible_groups.splice(index, 1);
    }
  }
};
</script>
